import { labelFn, valueKey } from '../../../Utilities/Autocomplete';
import { NavLink, useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { Field } from '@jlbelanger/formosa';
import Person from '../Partials/Person';
import { prettyDate } from '../../../Utilities/Helpers';
import PropTypes from 'prop-types';
import { relationshipTypes } from '../../../Utilities/Constants';
import { selectPeople } from '../../../../appSlice';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

export default function FamilyBasics({ row, setRow, setShowAddFamilyModal }) {
	const defaultNewRow = {
		person: '',
		frel: 'Unknown',
		mrel: 'Unknown',
	};
	const { tree } = useParams();
	const [newRow, setNewRow] = useState(defaultNewRow);
	const people = useSelector(selectPeople);

	const addRow = () => {
		const newRows = row.children ? [...row.children] : [];
		newRows.push({ ...newRow, id: `temp-${uuidv4()}`, type: 'family-person', family: { id: 'temp-this-id', type: 'family' } });
		setRow({ ...row, children: newRows });
		setNewRow({ ...defaultNewRow });
	};

	const removeRow = (e) => {
		const newRows = row.children ? [...row.children] : [];
		const id = e.target.getAttribute('data-id');
		const index = newRows.findIndex((r) => (r.id === id));
		if (index > -1) {
			newRows.splice(index, 1);
			setRow({ ...row, children: newRows });
		}
	};

	return (
		<>
			<div className="nowrap w-100">
				<Person row={row} setShowAddFamilyModal={setShowAddFamilyModal} name="husband" />
				<Person row={row} setShowAddFamilyModal={setShowAddFamilyModal} name="wife" />
			</div>

			<table>
				<thead>
					<tr>
						<th>Relation to Father</th>
						<th>Relation to Mother</th>
						<th>Name</th>
						<th>Birth Date</th>
						<th>Birth Place</th>
						<th>Death Date</th>
						<th>Death Place</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{row.children.map((r, i) => (
						<tr key={r.id}>
							<td>
								<Field hideBlank name={`children.${i}.frel`} options={relationshipTypes} type="select" />
							</td>
							<td>
								<Field hideBlank name={`children.${i}.mrel`} options={relationshipTypes} type="select" />
							</td>
							<td>
								<NavLink to={`/trees/${tree}/${r.person.type}/${r.person.id}`}>
									{r.person.meta.name}
								</NavLink>
							</td>
							<td>{prettyDate(r.person.meta.birth?.date)}</td>
							<td>{r.person.meta.birth?.place}</td>
							<td>{prettyDate(r.person.meta.death?.date)}</td>
							<td>{r.person.meta.death?.place}</td>
							<td>
								<div className="action-buttons">
									<button
										className="formosa-button button--danger-outline"
										data-id={r.id}
										onClick={removeRow}
										type="button"
									>
										Remove
									</button>
								</div>
							</td>
						</tr>
					))}
				</tbody>
				<tfoot>
					<tr>
						<td>
							<Field hideBlank name="frel" options={relationshipTypes} type="select" />
						</td>
						<td>
							<Field hideBlank name="mrel" options={relationshipTypes} type="select" />
						</td>
						<td>
							<Field
								labelFn={(option) => (labelFn(tree, option))}
								max={1}
								name="person"
								options={people}
								type="autocomplete"
								valueKey={valueKey}
							/>
						</td>
						<td />
						<td />
						<td />
						<td />
						<td>
							<button className="formosa-button button--success-outline" data-cy="add" onClick={addRow} type="button">Add</button>
						</td>
					</tr>
				</tfoot>
			</table>
		</>
	);
}

FamilyBasics.propTypes = {
	row: PropTypes.object.isRequired,
	setRow: PropTypes.func.isRequired,
	setShowAddFamilyModal: PropTypes.func.isRequired,
};
