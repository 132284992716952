import { createSlice } from '@reduxjs/toolkit';

const sortRows = (rows) => (
	rows.sort((a, b) => (a.slug.localeCompare(b.slug)))
);

const normalizeRow = (row) => {
	let name = row.name;
	let slug = row.slug;
	if (Object.prototype.hasOwnProperty.call(row, 'meta')) {
		if (row.meta.full_name) {
			name = row.meta.full_name;
		} else if (row.meta.name) {
			name = row.meta.name;
		}

		if (row.meta.slug) {
			slug = row.meta.slug;
		}
	}
	return { id: row.id, type: row.type, name, slug };
};

const initRows = (rows) => (sortRows(rows.map((row) => normalizeRow(row))));

export const appSlice = createSlice({
	name: 'app',
	initialState: {
		id: null,
		people: null,
		places: null,
		sources: null,
	},
	reducers: {
		addRow: (state, action) => {
			const { row, type } = action.payload;
			const newValue = [...state[type]];
			newValue.push(normalizeRow(row));
			return {
				...state,
				[type]: sortRows(newValue),
			};
		},
		init: (state, action) => {
			const { tree } = action.payload;
			return {
				...state,
				id: tree.id,
				people: initRows(tree.people),
				places: initRows(tree.places),
				sources: initRows(tree.sources),
			};
		},
		removeRow: (state, action) => {
			const { id, type } = action.payload;
			const newValue = [...state[type]];
			const index = state[type].findIndex((r) => (r.id === id));
			if (index > -1) {
				newValue.splice(index, 1);
			}
			return {
				...state,
				[type]: newValue,
			};
		},
		updateRow: (state, action) => {
			const { id, row, type } = action.payload;
			const newValue = [...state[type]];
			const index = state[type].findIndex((r) => (r.id === id));
			if (index > -1) {
				newValue[index] = normalizeRow(row);
			}
			return {
				...state,
				[type]: sortRows(newValue),
			};
		},
	},
});

export const {
	addRow,
	init,
	removeRow,
	updateRow,
} = appSlice.actions;

export const selectTreeId = (state) => state.app.id;
export const selectPeople = (state) => state.app.people;
export const selectPlaces = (state) => state.app.places;
export const selectSources = (state) => state.app.sources;

export const appReducer = appSlice.reducer;
