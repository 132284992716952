import React, { useContext, useEffect, useMemo, useState } from 'react';
import { capitalize } from '../Utilities/Helpers';
import { FormosaContext } from '@jlbelanger/formosa';
import MetaTitle from './MetaTitle';
import PropTypes from 'prop-types';
import TabButton from './TabButton';
import TabContext from './TabContext';
import TabList from './TabList';

export default function Tabs({ children, defaultTab, localStorageKey, title, updateUrl }) {
	const validChildren = children.filter((child) => (!!child));
	const validTabs = validChildren.map((child) => (child.props.id));

	const { disableWarningPrompt, enableWarningPrompt } = useContext(FormosaContext);
	const [currentTab, setCurrentTab] = useState(() => {
		if (updateUrl && window.location.hash && validTabs.includes(window.location.hash.substring(1))) {
			return window.location.hash.substring(1);
		}
		if (localStorageKey) {
			return window.localStorage.getItem(localStorageKey) || defaultTab;
		}
		return defaultTab;
	});

	const value = useMemo(() => ({
		currentTab,
	}), [currentTab]);

	const goToTab = (tabId) => {
		disableWarningPrompt();
		setCurrentTab(tabId);
		setTimeout(() => {
			enableWarningPrompt();
		}, 100); // TODO: This is hacky.
		if (localStorageKey) {
			window.localStorage.setItem(localStorageKey, tabId);
		}
	};

	const onHashChange = () => {
		const tab = document.location.hash.replace('#', '');
		goToTab(tab || defaultTab);
	};

	useEffect(() => {
		if (updateUrl) {
			window.addEventListener('hashchange', onHashChange);
		}

		return () => {
			if (updateUrl) {
				window.removeEventListener('hashchange', onHashChange);
			}
		};
	}, []);

	const currentTabName = ['names', 'basics'].includes(currentTab) ? '' : capitalize(currentTab);

	return (
		<>
			{title && <MetaTitle title={`${title} ${currentTabName}`.trim()} hideTitleText />}

			<TabList updateUrl={updateUrl}>
				{validChildren.map((child) => (
					<TabButton
						currentTab={currentTab}
						goToTab={goToTab}
						id={child.props.id}
						key={child.props.id}
						label={child.props.label}
						updateUrl={updateUrl}
					/>
				))}
			</TabList>

			<TabContext.Provider value={value}>
				{children}
			</TabContext.Provider>
		</>
	);
}

Tabs.propTypes = {
	children: PropTypes.node.isRequired,
	defaultTab: PropTypes.string.isRequired,
	localStorageKey: PropTypes.string,
	title: PropTypes.string,
	updateUrl: PropTypes.bool,
};

Tabs.defaultProps = {
	localStorageKey: '',
	title: '',
	updateUrl: false,
};
