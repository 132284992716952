import { Field } from '@jlbelanger/formosa';
import React from 'react';
import { toSlug } from '../../../Utilities/Helpers';

export default function TreeFields() {
	const autopopulate = (e, row) => {
		if (!row.name) {
			return {};
		}
		return {
			slug: toSlug(row.name),
		};
	};

	return (
		<div className="formosa-responsive">
			<Field afterChange={autopopulate} label="Name" name="name" required />

			<Field label="Permalink" name="slug" note="The permalink appears in the tree's URL. It must be unique." required />
		</div>
	);
}
