import React, { useEffect, useState } from 'react';
import { selectPeople, selectPlaces, selectSources } from '../../../../appSlice';
import AddPerson from '../../People/Add';
import AddPlace from '../../Places/Add';
import AddSource from '../../Sources/Add';
import PropTypes from 'prop-types';
import SidebarList from './SidebarList';
import TabPanel from '../../../Components/TabPanel';
import Tabs from '../../../Components/Tabs';
import { useSelector } from 'react-redux';

export default function TreeSidebar({ row }) {
	const people = useSelector(selectPeople);
	const places = useSelector(selectPlaces);
	const sources = useSelector(selectSources);
	const sidebarWidthKey = 'sidebar-width';
	const getMaxWidth = () => (Math.floor(window.innerWidth * 0.9));
	const constrainWidth = (w) => (Math.min(Math.max(261, w), window.MAX_WIDTH || getMaxWidth()));
	const [sidebarWidth, setSidebarWidth] = useState(constrainWidth(parseInt(window.localStorage.getItem(sidebarWidthKey), 10) || 370));

	const onMouseMove = (e) => {
		if (window.START_X !== undefined) {
			const newWidth = constrainWidth(window.ORIGINAL_WIDTH + e.clientX - window.START_X);
			setSidebarWidth(newWidth);
			window.NEW_WIDTH = newWidth;
		}
	};

	const onMouseDown = (e) => {
		window.MAX_WIDTH = getMaxWidth();
		window.START_X = e.clientX;
		window.ORIGINAL_WIDTH = sidebarWidth;
		window.NEW_WIDTH = undefined;
	};

	const onMouseUp = () => {
		if (window.NEW_WIDTH) {
			window.localStorage.setItem(sidebarWidthKey, window.NEW_WIDTH);
			window.START_X = undefined;
			window.ORIGINAL_WIDTH = window.NEW_WIDTH;
			window.NEW_WIDTH = undefined;
		}
	};

	const onWindowKeyDown = (e) => {
		if (e.key === 'Escape') {
			onMouseUp();
		}
	};

	const onResize = () => {
		window.MAX_WIDTH = getMaxWidth();
		if (sidebarWidth > window.MAX_WIDTH) {
			setSidebarWidth(window.MAX_WIDTH);
		}
	};

	const onResizeKeyDown = (e) => {
		const modifier = e.shiftKey ? 50 : 10;
		if (e.key === 'ArrowRight') {
			setSidebarWidth(constrainWidth(sidebarWidth + modifier));
		} else if (e.key === 'ArrowLeft') {
			setSidebarWidth(constrainWidth(sidebarWidth - modifier));
		}
	};

	useEffect(() => {
		document.addEventListener('mousemove', onMouseMove);
		document.addEventListener('mouseup', onMouseUp);
		window.addEventListener('keydown', onWindowKeyDown);
		window.addEventListener('resize', onResize);

		return () => {
			document.removeEventListener('mousemove', onMouseMove);
			document.removeEventListener('mouseup', onMouseUp);
			window.removeEventListener('keydown', onWindowKeyDown);
			window.removeEventListener('resize', onResize);
		};
	}, []);

	return (
		<aside id="sidebar" style={{ width: `${sidebarWidth}px` }}>
			<button
				aria-label="Resize sidebar width"
				draggable
				id="sidebar-resize"
				onKeyDown={onResizeKeyDown}
				onMouseDown={onMouseDown}
				type="button"
			/>
			<Tabs defaultTab="people" localStorageKey="sidebar-tab">
				<TabPanel id="people" label={`People (${people.length})`}>
					{people ? (
						<SidebarList addComponent={AddPerson} rows={people} tree={row.slug} singular="person" type="people" />
					) : (
						<div className="formosa-spinner" role="status">Loading...</div>
					)}
				</TabPanel>
				<TabPanel id="places" label={`Places (${places.length})`}>
					{places ? (
						<SidebarList addComponent={AddPlace} rows={places} tree={row.slug} singular="place" type="places" />
					) : (
						<div className="formosa-spinner" role="status">Loading...</div>
					)}
				</TabPanel>
				<TabPanel id="sources" label={`Sources (${sources.length})`}>
					{sources ? (
						<SidebarList addComponent={AddSource} rows={sources} tree={row.slug} singular="source" type="sources" />
					) : (
						<div className="formosa-spinner" role="status">Loading...</div>
					)}
				</TabPanel>
			</Tabs>
		</aside>
	);
}

TreeSidebar.propTypes = {
	row: PropTypes.object.isRequired,
};
