import { labelFn, valueKey } from '../Utilities/Autocomplete';
import React, { useState } from 'react';
import Citations from './Citations';
import { Field } from '@jlbelanger/formosa';
import PropTypes from 'prop-types';
import { selectPlaces } from '../../appSlice';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

export default function Events({ eventTypes, row, setRow, type }) {
	const defaultNewRow = {
		subtype: '',
		event_date: '',
		citations: [],
	};
	const { tree } = useParams();
	const places = useSelector(selectPlaces);
	const [newRow, setNewRow] = useState(defaultNewRow);
	const [showCitationsModal, setShowCitationsModal] = useState(false);

	const setExistingRowPart = (value, e) => {
		const newRows = [...row.events];
		const id = e.target.getAttribute('data-id');
		const index = newRows.findIndex((r) => (r.id === id));
		if (index > -1) {
			const newValue = { ...newRows[index] };
			newValue[e.target.name] = value;
			newRows[index] = newValue;
			setRow({ ...row, events: newRows });
		}
	};

	const setNewRowPart = (value, e) => {
		setNewRow({ ...newRow, [e.target.name]: value });
	};

	const addRow = () => {
		const newRows = row.events ? [...row.events] : [];
		newRows.push({ ...newRow, id: `temp-${uuidv4()}`, type: 'events', record: { id: 'temp-this-id', type } });
		setRow({ ...row, events: newRows });
		setNewRow({ ...defaultNewRow });
	};

	const removeRow = (e) => {
		const newRows = row.events ? [...row.events] : [];
		const id = e.target.getAttribute('data-id');
		const index = newRows.findIndex((r) => (r.id === id));
		if (index > -1) {
			newRows.splice(index, 1);
			setRow({ ...row, events: newRows });
		}
	};

	return (
		<>
			<table>
				<caption className="sr">Events</caption>
				<colgroup>
					<col />
					<col className="w-0" />
					<col className="w-100" />
					<col className="w-0" />
				</colgroup>
				<thead>
					<tr>
						<th id="label-event-subtype">Type</th>
						<th id="label-event-date">Date</th>
						<th id="label-event-place">Place</th>
						<th id="label-event-actions">Actions</th>
					</tr>
				</thead>
				<tbody>
					{row.events ? row.events.map((r) => (
						<tr key={r.id}>
							<td>
								<Field
									aria-labelledby="label-event-subtype"
									data-id={r.id}
									id={`event_subtype_${r.id}`}
									name="subtype"
									options={eventTypes}
									required
									setValue={setExistingRowPart}
									type="select"
									value={r.subtype}
								/>
							</td>
							<td>
								<Field
									aria-labelledby="label-event-date"
									data-id={r.id}
									id={`event_date_${r.id}`}
									name="event_date"
									setValue={setExistingRowPart}
									size={10}
									value={r.event_date || ''}
								/>
							</td>
							<td>
								<Field
									aria-labelledby="label-event-place"
									data-id={r.id}
									id={`event_place_${r.id}`}
									labelFn={(option) => (labelFn(tree, option))}
									max={1}
									name="place"
									options={places}
									setValue={setExistingRowPart}
									type="autocomplete"
									value={r.place || ''}
									valueKey={valueKey}
								/>
							</td>
							<td className="action-buttons">
								<div className="action-buttons">
									<button
										className="formosa-button button--danger-outline"
										data-id={r.id}
										onClick={removeRow}
										type="button"
									>
										Remove
									</button>
									<button
										className="formosa-button button--outline"
										data-id={r.id}
										onClick={(e) => { setShowCitationsModal(e); }}
										type="button"
									>
										{`Citations (${r.citations.length})`}
									</button>
								</div>
							</td>
						</tr>
					)) : null}
				</tbody>
				<tfoot>
					<tr>
						<td>
							<Field
								aria-labelledby="label-event-subtype"
								id="new_event_subtype"
								name="subtype"
								options={eventTypes}
								setValue={setNewRowPart}
								type="select"
								value={newRow.subtype}
							/>
						</td>
						<td>
							<Field
								aria-labelledby="label-event-date"
								id="new_event_date"
								maxLength={10}
								name="event_date"
								note="YYYY-MM-DD, YYYY, YYYY-MM, or MM-DD"
								setValue={setNewRowPart}
								size={10}
								value={newRow.event_date}
							/>
						</td>
						<td>
							<Field
								aria-labelledby="label-event-place"
								id="new_event_place"
								labelFn={(option) => (labelFn(tree, option))}
								max={1}
								name="place"
								options={places}
								setValue={setNewRowPart}
								type="autocomplete"
								value={newRow.place}
								valueKey={valueKey}
							/>
						</td>
						<td>
							<button className="formosa-button button--success-outline" data-cy="add" onClick={addRow} type="button">Add</button>
						</td>
					</tr>
				</tfoot>
			</table>

			{showCitationsModal && (
				<Citations
					row={row}
					showModal={showCitationsModal}
					setShowModal={setShowCitationsModal}
					setRow={setRow}
					type="events"
				/>
			)}
		</>
	);
}

Events.propTypes = {
	eventTypes: PropTypes.object.isRequired,
	row: PropTypes.object.isRequired,
	setRow: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
};
