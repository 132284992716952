import { addRow, selectTreeId } from '../../../appSlice';
import { beforeSubmit, errorMessageText } from '../../Utilities/Helpers';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Fields from './Partials/Fields';
import MyForm from '../../Components/MyForm';
import PropTypes from 'prop-types';

export default function PlaceAdd({ setShowAddModal }) {
	const dispatch = useDispatch();
	const history = useHistory();
	const { tree } = useParams();
	const id = useSelector(selectTreeId);
	const [row, setRow] = useState({
		tree: { id, type: 'trees' },
	});
	const relationshipNames = [
		'parent',
		'tree',
	];

	return (
		<MyForm
			afterSubmitSuccess={(response) => {
				history.push(`/trees/${tree}/places/${response.id}`);
				dispatch(addRow({ row: response, type: 'places' }));
				setShowAddModal(false);
			}}
			beforeSubmit={beforeSubmit}
			errorMessageText={errorMessageText}
			htmlId="add-form"
			method="POST"
			path="places"
			preventEmptyRequest
			relationshipNames={relationshipNames}
			row={row}
			setRow={setRow}
			successToastText="Place added successfully."
		>
			<Fields />
		</MyForm>
	);
}

PlaceAdd.propTypes = {
	setShowAddModal: PropTypes.func.isRequired,
};
