import React, { useState } from 'react';
import Citations from './Citations';
import { Field } from '@jlbelanger/formosa';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

export default function Notes({ row, setRow, type }) {
	const defaultNewRow = {
		content: '',
		citations: [],
	};
	const [newRow, setNewRow] = useState(defaultNewRow);
	const [showCitationsModal, setShowCitationsModal] = useState(false);

	const setExistingRowPart = (value, e) => {
		const newValues = [...row.notes];
		const id = e.target.getAttribute('data-id');
		const index = newValues.findIndex((r) => (r.id === id));
		if (index > -1) {
			const newValue = { ...newValues[index] };
			newValue[e.target.name] = value;
			newValues[index] = newValue;
			setRow({ ...row, notes: newValues });
		}
	};

	const setNewRowPart = (value, e) => {
		setNewRow({ ...newRow, [e.target.name]: value });
	};

	const addRow = () => {
		const newRows = row.notes ? [...row.notes] : [];
		newRows.push({ ...newRow, id: `temp-${uuidv4()}`, type: 'notes', record: { id: 'temp-this-id', type } });
		setRow({ ...row, notes: newRows });
		setNewRow({ ...defaultNewRow });
	};

	const removeRow = (e) => {
		const newRows = row.notes ? [...row.notes] : [];
		const id = e.target.getAttribute('data-id');
		const index = newRows.findIndex((r) => (r.id === id));
		if (index > -1) {
			newRows.splice(index, 1);
			setRow({ ...row, notes: newRows });
		}
	};

	return (
		<>
			<table>
				<caption className="sr">Notes</caption>
				<colgroup>
					<col className="w-100" />
					<col className="w-0" />
				</colgroup>
				<thead>
					<tr>
						<th id="label-note-content">Content</th>
						<th id="label-note-actions">Actions</th>
					</tr>
				</thead>
				<tbody>
					{row.notes ? row.notes.map((r) => (
						<tr key={r.id}>
							<td>
								<Field
									aria-labelledby="label-note-content"
									className="textarea--large"
									data-id={r.id}
									id={`note_content_${r.id}`}
									name="content"
									required
									setValue={setExistingRowPart}
									type="textarea"
									value={r.content}
								/>
							</td>
							<td>
								<div className="action-buttons">
									<button
										className="formosa-button button--danger-outline"
										data-id={r.id}
										onClick={removeRow}
										type="button"
									>
										Remove
									</button>
									<button
										className="formosa-button button--outline"
										data-id={r.id}
										onClick={(e) => { setShowCitationsModal(e); }}
										type="button"
									>
										{`Citations (${r.citations.length})`}
									</button>
								</div>
							</td>
						</tr>
					)) : null}
				</tbody>
				<tfoot>
					<tr>
						<td>
							<Field
								aria-labelledby="label-note-content"
								className="textarea--large"
								id="new_note_content"
								name="content"
								setValue={setNewRowPart}
								type="textarea"
								value={newRow.content}
							/>
						</td>
						<td>
							<button className="formosa-button button--success-outline" data-cy="add" onClick={addRow} type="button">Add</button>
						</td>
					</tr>
				</tfoot>
			</table>

			{showCitationsModal && (
				<Citations
					row={row}
					showModal={showCitationsModal}
					setShowModal={setShowCitationsModal}
					setRow={setRow}
					type="notes"
				/>
			)}
		</>
	);
}

Notes.propTypes = {
	row: PropTypes.object.isRequired,
	setRow: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
};
