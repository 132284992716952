import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import Auth from './Utilities/Auth';
import Error404 from './Error404';
import ForgotPassword from './Pages/Auth/ForgotPassword';
import Login from './Pages/Auth/Login';
import Profile from './Pages/Users/Edit';
import React from 'react';
import Register from './Pages/Auth/Register';
import ResetPassword from './Pages/Auth/ResetPassword';
import TreesList from './Pages/Trees/List';
import TreesView from './Pages/Trees/View';
import VerifyEmail from './Pages/Auth/VerifyEmail';

export default function Routes() {
	const location = useLocation();

	if (!Auth.isLoggedIn()) {
		return (
			<article className="contain contain-small" id="article">
				<section id="article-content">
					<Switch>
						<Route exact path="/"><Login /></Route>
						<Route exact path="/register"><Register /></Route>
						<Route exact path="/forgot-password"><ForgotPassword /></Route>
						<Route exact path="/reset-password/:token"><ResetPassword /></Route>
						<Route exact path="/verify-email"><VerifyEmail /></Route>
						<Route><Redirect to={`/?redirect=${encodeURIComponent(`${location.pathname}${location.search}`)}`} /></Route>
					</Switch>
				</section>
			</article>
		);
	}

	return (
		<Switch>
			<Route exact path="/">
				<article className="contain" id="article"><section id="article-content"><TreesList /></section></article>
			</Route>
			<Route exact path="/profile">
				<article className="contain" id="article"><section id="article-content"><Profile /></section></article>
			</Route>
			<Route path="/trees/:tree">
				<TreesView />
			</Route>
			<Route>
				<article className="contain" id="article"><section id="article-content"><Error404 /></section></article>
			</Route>
		</Switch>
	);
}
