import { labelFn, valueKey } from '../../../Utilities/Autocomplete';
import { NavLink, useParams } from 'react-router-dom';
import { Field } from '@jlbelanger/formosa';
import { prettyDate } from '../../../Utilities/Helpers';
import PropTypes from 'prop-types';
import React from 'react';
import { selectPeople } from '../../../../appSlice';
import { useSelector } from 'react-redux';

export default function FamilyPerson({ name, row, setShowAddFamilyModal }) {
	const { tree } = useParams();
	const people = useSelector(selectPeople);

	const addParents = (e) => {
		setShowAddFamilyModal({
			e,
			title: 'Parents',
			defaultRow: {
				children: [row[name]],
			},
		});
	};

	return (
		<div className="w-100">
			<div>
				{row[name].parents.length > 0 ? (
					<NavLink className="formosa-button button--link" to={`/trees/${tree}/families/${row[name].parents[0].id}`}>
						{row[name].parents[0].meta.name}
					</NavLink>
				) : (
					<button className="formosa-button button--link" onClick={addParents} type="button">Add Parents</button>
				)}
			</div>
			<dl>
				<div>
					<dt>Name</dt>
					<dd>
						<Field
							labelFn={(option) => (labelFn(tree, option))}
							max={1}
							name={name}
							options={people}
							type="autocomplete"
							valueKey={valueKey}
						/>
					</dd>
				</div>
				{row[name] && (
					<>
						<div>
							<dt>Birth Date</dt>
							<dd>{prettyDate(row[name].meta.birth?.date)}</dd>
						</div>
						<div>
							<dt>Birth Place</dt>
							<dd>{row[name].meta.birth?.place}</dd>
						</div>
						{(row[name].meta.death) && (
							<>
								<div>
									<dt>Death Date</dt>
									<dd>{prettyDate(row[name].meta.death?.date)}</dd>
								</div>
								<div>
									<dt>Death Place</dt>
									<dd>{row[name].meta.death?.place}</dd>
								</div>
							</>
						)}
					</>
				)}
			</dl>
		</div>
	);
}

FamilyPerson.propTypes = {
	name: PropTypes.string.isRequired,
	row: PropTypes.object.isRequired,
	setShowAddFamilyModal: PropTypes.func.isRequired,
};
