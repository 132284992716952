import { NavLink } from 'react-router-dom';
import React from 'react';

export const labelFn = (tree, option) => (
	<NavLink
		className="crudnick-autocomplete-link"
		to={`/trees/${tree}/${option.type}/${option.id}`}
	>
		{option.name || option.meta.name}
	</NavLink>
);

export const valueKey = (option) => ({ id: option.id, type: option.type });
