import { NavLink, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

export default function PlaceSources({ row }) {
	const { tree } = useParams();

	if (!row.sources || row.sources.length <= 0) {
		return (
			<p>No sources.</p>
		);
	}

	return (
		<table>
			<thead>
				<tr>
					<th>Record</th>
				</tr>
			</thead>
			<tbody>
				{row.sources.map((source) => (
					<tr key={source.id}>
						<td>
							<NavLink to={`/trees/${tree}/${source.type}/${source.id}`}>
								{source.name}
							</NavLink>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
}

PlaceSources.propTypes = {
	row: PropTypes.object.isRequired,
};
