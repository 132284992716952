import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import TabContext from './TabContext';

export default function TabPanel({ children, id }) {
	const { currentTab } = useContext(TabContext);

	return (
		<div aria-labelledby={`tab-${id}`} className="tab-panel" hidden={currentTab !== id} id={id} role="tabpanel" tabIndex="0">
			{children}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node.isRequired,
	id: PropTypes.string.isRequired,
};
