import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

export default function TabList({ children }) {
	const listRef = useRef(null);
	const [tabFocusIndex, setTabFocusIndex] = useState(-1);

	// https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/Tab_Role
	const onKeyDown = (e) => {
		if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
			const tabs = listRef.current.querySelectorAll('[role="tab"]');
			if (tabFocusIndex > -1) {
				tabs[tabFocusIndex].setAttribute('tabindex', -1);
			}
			let newTabFocusIndex = 0;

			if (e.key === 'ArrowRight') {
				if (tabFocusIndex >= (tabs.length - 1)) {
					newTabFocusIndex = 0;
				} else {
					newTabFocusIndex = tabFocusIndex + 1;
				}
			} else if (e.key === 'ArrowLeft') {
				if (tabFocusIndex <= 0) {
					newTabFocusIndex = tabs.length - 1;
				} else {
					newTabFocusIndex = tabFocusIndex - 1;
				}
			}

			tabs[newTabFocusIndex].setAttribute('tabindex', 0);
			tabs[newTabFocusIndex].focus();
			setTabFocusIndex(newTabFocusIndex);
		}
	};

	return (
		<div className="tab-list" ref={listRef} role="tablist" tabIndex="0" onKeyDown={onKeyDown}>
			{children}
		</div>
	);
}

TabList.propTypes = {
	children: PropTypes.node.isRequired,
};
