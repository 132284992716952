import { Alert, Api, FormosaContext } from '@jlbelanger/formosa';
import { beforeSubmit, errorMessageText } from '../../Utilities/Helpers';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Error from '../../Error';
import FamilyAdd from './Add';
import { ReactComponent as FamilyIcon } from '../../../svg/family.svg';
import MetaTitle from '../../Components/MetaTitle';
import Modal from '../../Components/Modal';
import MyForm from '../../Components/MyForm';
import Submit from '../../Components/Submit';
import Tabs from './Partials/Tabs';
import { updateRow } from '../../../appSlice';
import { useDispatch } from 'react-redux';

export default function FamilyEdit() {
	const api = Api.instance();
	const { addToast, disableWarningPrompt, enableWarningPrompt } = useContext(FormosaContext);
	const dispatch = useDispatch();
	const history = useHistory();
	const { id, tree } = useParams();
	const [row, setRow] = useState(null);
	const [error, setError] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showAddFamilyModal, setShowAddFamilyModal] = useState(false);
	const [deleteError, setDeleteError] = useState(false);
	const relationshipNames = [
		'children',
		'children.person',
		'children.person.events',
		'children.person.events.citations',
		'children.person.events.citations.source',
		'children.person.events.place',
		'children.person.names',

		'husband',
		'husband.events',
		'husband.events.citations',
		'husband.events.citations.source',
		'husband.events.place',
		'husband.parents',
		'husband.parents.husband',
		'husband.parents.wife',
		'husband.names',

		'events',
		'events.citations',
		'events.citations.source',
		'events.place',

		'notes',
		'notes.citations',
		'notes.citations.source',

		'wife',
		'wife.events',
		'wife.events.citations',
		'wife.events.citations.source',
		'wife.events.place',
		'wife.parents',
		'wife.parents.husband',
		'wife.parents.wife',
		'wife.names',
	];
	const relationshipsToAddOnly = [
		'children.events.record',

		'events.citations.record',
		'events.record',

		'husband.events.record',

		'notes.citations.record',
		'notes.record',

		'wife.events.record',
	];
	const relationshipsToGetOnly = [];
	const include = `include=${relationshipNames.concat(relationshipsToGetOnly).join(',')}`;

	useEffect(() => {
		setRow(null);
		api(`families/${id}?filter[tree.slug][eq]=${tree}&${include}`)
			.catch((response) => {
				setError(response);
			})
			.then((response) => {
				if (!response) {
					return;
				}
				setRow(response);
			});
	}, [id, tree]);

	if (error) {
		return (
			<Error error={error} />
		);
	}

	if (row === null) {
		return null;
	}

	const deleteRow = () => {
		setShowDeleteModal(false);
		disableWarningPrompt();
		Api.delete(`families/${id}`)
			.catch((response) => {
				setDeleteError(errorMessageText(response));
			})
			.then((response) => {
				enableWarningPrompt();
				if (!response) {
					return;
				}
				addToast('Family deleted successfully.', 'success');
				history.push(`/trees/${tree}`);
			});
	};

	return (
		<>
			<MetaTitle icon={(<FamilyIcon />)} title={row.meta.name}>
				<button
					className="formosa-button formosa-button--danger"
					onClick={(e) => {
						setDeleteError(false);
						setShowDeleteModal(e);
					}}
					type="button"
				>
					Delete
				</button>
				<Submit />
			</MetaTitle>

			{deleteError && (<Alert type="error">{deleteError}</Alert>)}

			<MyForm
				afterSubmitSuccess={(response) => {
					setRow(response);
					dispatch(updateRow({ id, row: response, type: 'families' }));
				}}
				beforeSubmit={beforeSubmit}
				errorMessageText={errorMessageText}
				htmlId="form"
				id={id}
				method="PUT"
				params={include}
				path="families"
				preventEmptyRequest
				relationshipNames={relationshipNames.concat(relationshipsToAddOnly)}
				row={row}
				setRow={setRow}
				successToastText="Family saved successfully."
			>
				<Tabs row={row} setRow={setRow} setShowAddFamilyModal={setShowAddFamilyModal} />
			</MyForm>

			{showDeleteModal && (
				<Modal
					event={showDeleteModal}
					okButtonClass="formosa-button--danger"
					okButtonText="Delete"
					onClickOk={deleteRow}
					onClickCancel={() => { setShowDeleteModal(false); }}
					text="Are you sure you want to delete this family?"
				/>
			)}

			{showAddFamilyModal && (
				<Modal
					event={showAddFamilyModal.e}
					okButtonClass="formosa-button--success"
					okButtonText="Add"
					okButtonAttributes={{
						form: 'add-form',
						type: 'submit',
					}}
					onClickCancel={() => {
						setShowAddFamilyModal(false);
					}}
				>
					<h2>{`Add ${showAddFamilyModal.title}`}</h2>

					<FamilyAdd showAddModal={showAddFamilyModal} setShowAddModal={setShowAddFamilyModal} />
				</Modal>
			)}
		</>
	);
}
