import { beforeSubmit, errorMessageText } from '../../Utilities/Helpers';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Fields from './Partials/Fields';
import MyForm from '../../Components/MyForm';
import PropTypes from 'prop-types';
import { selectTreeId } from '../../../appSlice';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

export default function FamilyAdd({ showAddModal, setShowAddModal }) {
	const history = useHistory();
	const { tree } = useParams();
	const id = useSelector(selectTreeId);
	const defaultRow = showAddModal.defaultRow;
	const [row, setRow] = useState({
		children: [],
		events: [
			{
				id: `temp-${uuidv4()}`,
				type: 'events',
				subtype: 'MARR',
				record: {
					id: 'temp-this-id',
					type: 'families',
				},
			},
		],
		husband: null,
		wife: null,
		tree: { id, type: 'trees' },
		...defaultRow,
	});
	const relationshipNames = [
		'children',
		'children.events',
		'children.events.place',
		'children.events.record',
		'children.names',

		'husband',
		'husband.events',
		'husband.events.place',
		'husband.events.record',
		'husband.names',

		'events',
		'events.place',
		'events.record',

		'tree',

		'wife',
		'wife.events',
		'wife.events.place',
		'wife.events.record',
		'wife.names',
	];

	return (
		<MyForm
			afterSubmitSuccess={(response) => {
				history.push(`/trees/${tree}/families/${response.id}`);
				setShowAddModal(false);
			}}
			beforeSubmit={beforeSubmit}
			defaultRow={defaultRow}
			errorMessageText={errorMessageText}
			htmlId="add-form"
			method="POST"
			path="families"
			preventEmptyRequest
			relationshipNames={relationshipNames}
			row={row}
			setRow={setRow}
			successToastText="Family added successfully."
		>
			<Fields />
		</MyForm>
	);
}

FamilyAdd.propTypes = {
	showAddModal: PropTypes.object.isRequired,
	setShowAddModal: PropTypes.func.isRequired,
};
