import Basics from '../Tabs/Basics';
import Events from '../../../Components/Events';
import { familyEventTypes } from '../../../Utilities/Constants';
import Notes from '../../../Components/Notes';
import PropTypes from 'prop-types';
import React from 'react';
import TabPanel from '../../../Components/TabPanel';
import Tabs from '../../../Components/Tabs';

export default function FamilyTabs({ row, setRow, setShowAddFamilyModal }) {
	return (
		<Tabs defaultTab="basics" title={row.id ? row.meta.name : ''} updateUrl>
			<TabPanel id="basics" label="Basics">
				<Basics row={row} setRow={setRow} setShowAddFamilyModal={setShowAddFamilyModal} />
			</TabPanel>
			<TabPanel id="events" label={`Events (${row.events.length})`}>
				<Events eventTypes={familyEventTypes} row={row} setRow={setRow} type="events" />
			</TabPanel>
			<TabPanel id="notes" label={`Notes (${row.notes.length})`}>
				<Notes row={row} setRow={setRow} type="sources" />
			</TabPanel>
		</Tabs>
	);
}

FamilyTabs.propTypes = {
	row: PropTypes.object.isRequired,
	setRow: PropTypes.func.isRequired,
	setShowAddFamilyModal: PropTypes.func.isRequired,
};
