import { labelFn, valueKey } from '../../../Utilities/Autocomplete';
import { Field } from '@jlbelanger/formosa';
import PropTypes from 'prop-types';
import React from 'react';
import { selectPlaces } from '../../../../appSlice';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function SourceFields({ formType, row }) {
	const places = useSelector(selectPlaces);
	const { tree } = useParams();
	const hasUrl = row.url && row.url.startsWith('http');

	return (
		<div className="formosa-responsive">
			<Field label="Name" name="name" required />

			<Field
				className={hasUrl ? 'formosa-prefix' : null}
				label="URL"
				name="url"
				postfix={hasUrl ? (
					<a className="formosa-button button--secondary formosa-postfix" href={row.url} rel="noreferrer" target="_blank">
						View
					</a>
				) : <div />}
				type="url"
			/>

			<Field
				label="Date"
				maxLength={10}
				name="source_date"
				note="YYYY-MM-DD, YYYY, YYYY-MM, or MM-DD"
				size={10}
			/>

			{places.length > 0 && (
				<Field
					label="Place"
					labelFn={(option) => (labelFn(tree, option))}
					max={1}
					name="place"
					options={places}
					type="autocomplete"
					valueKey={valueKey}
				/>
			)}

			{formType !== 'add' && <Field className="textarea--large" label="Content" name="content" type="textarea" />}
		</div>
	);
}

SourceFields.propTypes = {
	formType: PropTypes.string.isRequired,
	row: PropTypes.object.isRequired,
};
