import { labelFn, valueKey } from '../../../Utilities/Autocomplete';
import { selectPeople, selectPlaces } from '../../../../appSlice';
import { Field } from '@jlbelanger/formosa';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function FamilyFields() {
	const { tree } = useParams();
	const people = useSelector(selectPeople);
	const places = useSelector(selectPlaces);

	return (
		<div className="formosa-responsive">
			<Field
				label="Father"
				labelFn={(option) => (labelFn(tree, option))}
				max={1}
				name="husband"
				options={people}
				type="autocomplete"
				valueKey={valueKey}
			/>

			<Field
				label="Mother"
				labelFn={(option) => (labelFn(tree, option))}
				max={1}
				name="wife"
				options={people}
				type="autocomplete"
				valueKey={valueKey}
			/>

			<Field
				label="Marriage Date"
				maxLength={10}
				name="events.0.event_date"
				note="YYYY-MM-DD, YYYY, YYYY-MM, or MM-DD"
				size={10}
			/>

			<Field
				label="Marriage Place"
				labelFn={(option) => (labelFn(tree, option))}
				max={1}
				name="events.0.place"
				options={places}
				type="autocomplete"
				valueKey={valueKey}
			/>

			<Field
				label="Children"
				labelFn={(option) => (labelFn(tree, option))}
				name="children"
				options={people}
				type="autocomplete"
				valueKey={valueKey}
			/>
		</div>
	);
}
