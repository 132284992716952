import { labelFn, valueKey } from '../../../Utilities/Autocomplete';
import { Field } from '@jlbelanger/formosa';
import React from 'react';
import { selectPlaces } from '../../../../appSlice';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function PlaceFields() {
	const places = useSelector(selectPlaces);
	const { tree } = useParams();

	return (
		<div className="formosa-responsive">
			<Field label="Name" name="name" required />

			<Field label="Short Name" name="short_name" />

			{places.length > 0 && (
				<Field
					label="Parent"
					labelFn={(option) => (labelFn(tree, option))}
					max={1}
					name="parent"
					options={places}
					type="autocomplete"
					valueKey={valueKey}
				/>
			)}
		</div>
	);
}
