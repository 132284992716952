import { NavLink, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

export default function PlaceChildren({ row }) {
	const { tree } = useParams();

	if (!row.children || row.children.length <= 0) {
		return (
			<p>No children.</p>
		);
	}

	return (
		<table>
			<thead>
				<tr>
					<th>Record</th>
				</tr>
			</thead>
			<tbody>
				{row.children.map((child) => (
					<tr key={child.id}>
						<td>
							<NavLink to={`/trees/${tree}/${child.type}/${child.id}`}>
								{child.meta.full_name}
							</NavLink>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
}

PlaceChildren.propTypes = {
	row: PropTypes.object.isRequired,
};
