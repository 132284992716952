import Auth from './Auth';
import { DateTime } from 'luxon';

export const prettyDate = (d) => {
	if (!d) {
		return d;
	}
	if (d.length === 4) {
		return d;
	}
	if (d.length === 7) {
		return DateTime.fromISO(d).toFormat('LLL yyyy');
	}
	return DateTime.fromISO(d).toFormat('LLL d, yyyy');
};

export const capitalize = (s) => (
	s.replace(/(?:^|\s)\S/g, (a) => (a.toUpperCase()))
);

export const errorMessageText = (response, logout = true) => {
	if (logout && response.status === 401) {
		return Auth.logout(response.status);
	}
	return `Error: ${response.errors.map((e) => (e.title)).join(' ')}`;
};

export const afterSubmitFailure = (error) => {
	if (error.status === 401) {
		Auth.logout(error.status);
	}
};

export const beforeSubmit = () => {
	if (!document.activeElement) {
		return true;
	}

	const tfoot = document.activeElement.closest('tfoot');
	if (!tfoot) {
		return true;
	}

	const button = tfoot.querySelector('[data-cy="add"]');
	const inputs = Array.from(tfoot.querySelectorAll('input,select,textarea'));
	if (button && inputs.some((i) => (i.value))) {
		button.click();
		return false;
	}
	return true;
};

export const toSlug = (value) => {
	if (!value) {
		return '';
	}
	return value
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '')
		.toLowerCase()
		.replace(/ & /g, '-and-')
		.replace(/<[^>]+>/g, '')
		.replace(/['’.]/g, '')
		.replace(/[^a-z0-9-]+/g, '-')
		.replace(/^-+/, '')
		.replace(/-+$/, '')
		.replace(/--+/g, '-');
};
