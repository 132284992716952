import { Alert, Api, FormosaContext } from '@jlbelanger/formosa';
import React, { useContext, useEffect, useState } from 'react';
import Error from '../../Error';
import { errorMessageText } from '../../Utilities/Helpers';
import Fields from './Partials/Fields';
import { Link } from 'react-router-dom';
import MetaTitle from '../../Components/MetaTitle';
import Modal from '../../Components/Modal';
import MyForm from '../../Components/MyForm';

export default function TreeList() {
	const api = Api.instance();
	const { addToast } = useContext(FormosaContext);
	const [newTree, setNewTree] = useState({});
	const [rows, setRows] = useState(null);
	const [error, setError] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteError, setDeleteError] = useState(false);
	const [showSettingsModal, setShowSettingsModal] = useState(false);
	const [settingsError, setSettingsError] = useState(false);

	useEffect(() => {
		api('trees?sort=name')
			.catch((response) => {
				setError(response);
			})
			.then((response) => {
				if (!response) {
					return;
				}
				setRows(response);
			});
	}, []);

	if (error) {
		return (
			<Error error={error} />
		);
	}

	if (rows === null) {
		return (
			<MetaTitle title="Trees" />
		);
	}

	const deleteRow = () => {
		const id = showDeleteModal.row.id;
		setShowDeleteModal(false);
		Api.delete(`trees/${id}`)
			.catch((response) => {
				setDeleteError(errorMessageText(response));
			})
			.then((response) => {
				if (!response) {
					return;
				}
				addToast('Tree deleted successfully.', 'success');
				const newRows = [...rows];
				const pos = newRows.findIndex((row) => (row.id === id));
				if (pos > -1) {
					newRows.splice(pos, 1);
					setRows(newRows);
				}
			});
	};

	return (
		<>
			<MetaTitle title="Trees" />

			{rows.length > 0 ? (
				<>
					<h2>My trees</h2>
					{deleteError && (<Alert type="error">{deleteError}</Alert>)}
					<table>
						<thead>
							<tr>
								<th className="w-100" scope="col">Name</th>
								<th scope="col">Actions</th>
							</tr>
						</thead>
						<tbody>
							{rows.map((row) => (
								<tr key={row.id}>
									<th scope="row">{row.name}</th>
									<td>
										<div className="nowrap">
											<Link className="formosa-button" to={`/trees/${row.slug}`}>View</Link>
											<button
												className="formosa-button button--secondary"
												onClick={(e) => {
													setDeleteError(false);
													setShowSettingsModal({ e, row });
												}}
												type="button"
											>
												Settings
											</button>
											<button
												className="formosa-button formosa-button--danger"
												data-name={row.name}
												data-id={row.id}
												onClick={(e) => {
													setDeleteError(false);
													setShowDeleteModal({ e, row });
												}}
												type="button"
											>
												Delete
											</button>
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</>
			) : null}

			{showDeleteModal && (
				<Modal
					event={showDeleteModal.e}
					okButtonClass="formosa-button--danger"
					okButtonText="Delete"
					onClickOk={deleteRow}
					onClickCancel={() => { setShowDeleteModal(false); }}
					text={`Are you sure you want to delete ${showDeleteModal.row.name}?`}
				/>
			)}

			{showSettingsModal && (
				<Modal
					event={showSettingsModal.e}
					okButtonText="Save"
					okButtonAttributes={{
						form: 'edit-form',
						type: 'submit',
					}}
					onClickCancel={() => { setShowSettingsModal(false); }}
				>
					<MyForm
						afterSubmitSuccess={() => {
							const newRows = [...rows];
							const index = newRows.findIndex((r) => (r.id === showSettingsModal.row.id));
							if (index > -1) {
								newRows[index] = showSettingsModal.row;
								setRows(newRows);
							}
							setShowSettingsModal(false);
						}}
						afterSubmitFailure={(response) => { setSettingsError(errorMessageText(response)); }}
						htmlId="edit-form"
						id={showSettingsModal.row.id}
						method="PUT"
						path="trees"
						preventEmptyRequest
						row={showSettingsModal.row}
						setRow={(newRow) => {
							setShowSettingsModal({ ...showSettingsModal, row: newRow });
						}}
						successToastText="Tree saved successfully."
					>
						{settingsError && (<Alert type="error">{settingsError}</Alert>)}

						<Fields row={showSettingsModal.row} />
					</MyForm>
				</Modal>
			)}

			<MyForm
				afterSubmitSuccess={(response) => {
					const newRows = [...rows, response].sort((a, b) => (a.name.localeCompare(b.name)));
					setRows(newRows);
					setNewTree({});
				}}
				className="box"
				errorMessageText={errorMessageText}
				method="POST"
				path="trees"
				preventEmptyRequest
				row={newTree}
				setRow={setNewTree}
				successToastText="Tree created successfully."
			>
				<h2>Create new tree</h2>
				<Fields row={newTree} />
				<button className="formosa-button formosa-button--success right" type="submit">Create</button>
			</MyForm>
		</>
	);
}
