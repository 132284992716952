import { Route, Switch } from 'react-router-dom';
import FamiliesEdit from './Pages/Families/Edit';
import PeopleEdit from './Pages/People/Edit';
import PlacesEdit from './Pages/Places/Edit';
import React from 'react';
import SourcesEdit from './Pages/Sources/Edit';

export default function RoutesTree() {
	return (
		<Switch>
			<Route exact path="/trees/:tree/families/:id"><FamiliesEdit /></Route>
			<Route exact path="/trees/:tree/people/:id"><PeopleEdit /></Route>
			<Route exact path="/trees/:tree/places/:id"><PlacesEdit /></Route>
			<Route exact path="/trees/:tree/sources/:id"><SourcesEdit /></Route>
		</Switch>
	);
}
