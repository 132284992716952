import { familyEventTypes, personEventTypes, personFactTypes } from '../../../Utilities/Constants';
import { NavLink, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

export default function SourceCitations({ row }) {
	const { tree } = useParams();

	if (!row.citations || row.citations.length <= 0) {
		return (
			<p>No citations.</p>
		);
	}

	const types = { ...familyEventTypes, ...personEventTypes, ...personFactTypes };

	return (
		<table>
			<thead>
				<tr>
					<th>Record</th>
					<th>Type</th>
					<th>Value</th>
					<th>Page</th>
					<th>Content</th>
				</tr>
			</thead>
			<tbody>
				{row.citations.map((citation) => {
					const record = citation.record.record;
					return (
						<tr key={citation.id}>
							<td>
								<NavLink to={`/trees/${tree}/${record.type}/${record.id}#${record.type}`}>
									{record.meta.name}
								</NavLink>
							</td>
							<td>{citation.record.subtype ? types[citation.record.subtype] : citation.record.type}</td>
							<td>
								{citation.record.type === 'events' ? (
									<>
										{citation.record.event_date}
										{citation.record.event_date && citation.record.place ? (<br />) : null}
										{citation.record.place ? citation.record.place.meta.full_name : null}
									</>
								) : null}
								{citation.record.type === 'facts' ? citation.record.fact_value : null}
								{citation.record.type === 'names' ? record.meta.name : null}
								{citation.record.type === 'notes' ? citation.record.content : null}
							</td>
							<td>{citation.source_page}</td>
							<td>{citation.content}</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
}

SourceCitations.propTypes = {
	row: PropTypes.object.isRequired,
};
