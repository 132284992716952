import { combineSlices, configureStore } from '@reduxjs/toolkit';
import { appSlice } from './appSlice';
import { setupListeners } from '@reduxjs/toolkit/query';

const rootReducer = combineSlices(appSlice);

export const makeStore = (preloadedState) => {
	const store = configureStore({
		reducer: rootReducer,
		preloadedState,
	});
	setupListeners(store.dispatch);
	return store;
};

export const store = makeStore();
