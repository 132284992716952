import Events from '../../../Components/Events';
import Facts from '../Tabs/Facts';
import Families from '../Tabs/Families';
import Names from '../Tabs/Names';
import Notes from '../../../Components/Notes';
import { personEventTypes } from '../../../Utilities/Constants';
import PropTypes from 'prop-types';
import React from 'react';
import TabPanel from '../../../Components/TabPanel';
import Tabs from '../../../Components/Tabs';

export default function PersonTabs({ row, setRow, setShowAddFamilyModal }) {
	return (
		<Tabs defaultTab="families" title={row.id ? row.meta.name : ''} updateUrl>
			<TabPanel id="families" label="Families">
				<Families row={row} setShowAddFamilyModal={setShowAddFamilyModal} />
			</TabPanel>
			<TabPanel id="names" label={`Names (${row.names.length})`}>
				<Names row={row} setRow={setRow} />
			</TabPanel>
			<TabPanel id="events" label={`Events (${row.events.length})`}>
				<Events eventTypes={personEventTypes} row={row} setRow={setRow} type="people" />
			</TabPanel>
			<TabPanel id="facts" label={`Facts (${row.facts.length})`}>
				<Facts row={row} setRow={setRow} />
			</TabPanel>
			<TabPanel id="notes" label={`Notes (${row.notes.length})`}>
				<Notes row={row} setRow={setRow} type="people" />
			</TabPanel>
		</Tabs>
	);
}

PersonTabs.propTypes = {
	row: PropTypes.object.isRequired,
	setRow: PropTypes.func.isRequired,
	setShowAddFamilyModal: PropTypes.func.isRequired,
};
