import { labelFn, valueKey } from '../../../Utilities/Autocomplete';
import { Field } from '@jlbelanger/formosa';
import React from 'react';
import { selectPlaces } from '../../../../appSlice';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function PersonFields() {
	const { tree } = useParams();
	const places = useSelector(selectPlaces);

	return (
		<div className="formosa-responsive">
			<Field label="First Name" name="names.0.given" required />

			<Field label="Last Name" name="names.0.surname" required />

			<Field
				label="Birth Date"
				maxLength={10}
				name="events.0.event_date"
				note="YYYY-MM-DD, YYYY, YYYY-MM, or MM-DD"
				size={10}
			/>

			<Field
				label="Birth Place"
				labelFn={(option) => (labelFn(tree, option))}
				max={1}
				name="events.0.place"
				options={places}
				type="autocomplete"
				valueKey={valueKey}
			/>
		</div>
	);
}
