import { familyEventTypes, personEventTypes } from '../../../Utilities/Constants';
import { NavLink, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

export default function PlaceEvents({ row }) {
	const { tree } = useParams();

	if (!row.events || row.events.length <= 0) {
		return (
			<p>No events.</p>
		);
	}

	const eventTypes = { ...personEventTypes, ...familyEventTypes };

	return (
		<table>
			<thead>
				<tr>
					<th>Record</th>
					<th>Type</th>
					<th>Date</th>
				</tr>
			</thead>
			<tbody>
				{row.events.map((event) => (
					<tr key={event.id}>
						<td>
							<NavLink to={`/trees/${tree}/${event.record.type}/${event.record.id}#${event.record.type}`}>
								{event.record.meta.name}
							</NavLink>
						</td>
						<td>{eventTypes[event.subtype]}</td>
						<td>{event.event_date}</td>
					</tr>
				))}
			</tbody>
		</table>
	);
}

PlaceEvents.propTypes = {
	row: PropTypes.object.isRequired,
};
