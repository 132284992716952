import React, { useState } from 'react';
import Citations from '../../../Components/Citations';
import { Field } from '@jlbelanger/formosa';
import { personFactTypes } from '../../../Utilities/Constants';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

export default function PersonFacts({ row, setRow }) {
	const defaultNewRow = {
		subtype: '',
		fact_value: '',
		citations: [],
	};
	const [newRow, setNewRow] = useState(defaultNewRow);
	const [showCitationsModal, setShowCitationsModal] = useState(false);

	const setExistingRowPart = (value, e) => {
		const newRows = [...row.facts];
		const id = e.target.getAttribute('data-id');
		const index = newRows.findIndex((r) => (r.id === id));
		if (index > -1) {
			const newValue = { ...newRows[index] };
			newValue[e.target.name] = value;
			newRows[index] = newValue;
			setRow({ ...row, facts: newRows });
		}
	};

	const setNewRowPart = (value, e) => {
		setNewRow({ ...newRow, [e.target.name]: value });
	};

	const addRow = () => {
		const newRows = row.facts ? [...row.facts] : [];
		newRows.push({ ...newRow, id: `temp-${uuidv4()}`, type: 'facts', person: { id: 'temp-this-id', type: 'people' } });
		setRow({ ...row, facts: newRows });
		setNewRow({ ...defaultNewRow });
	};

	const removeRow = (e) => {
		const newRows = row.facts ? [...row.facts] : [];
		const id = e.target.getAttribute('data-id');
		const index = newRows.findIndex((r) => (r.id === id));
		if (index > -1) {
			newRows.splice(index, 1);
			setRow({ ...row, facts: newRows });
		}
	};

	return (
		<>
			<table>
				<caption className="sr">Facts</caption>
				<colgroup>
					<col />
					<col className="w-100" />
					<col className="w-0" />
				</colgroup>
				<thead>
					<tr>
						<th id="label-fact-subtype">Type</th>
						<th id="label-fact-value">Value</th>
						<th id="label-fact-actions">Actions</th>
					</tr>
				</thead>
				<tbody>
					{row.facts ? row.facts.map((r) => (
						<tr key={r.id}>
							<td>
								<Field
									aria-labelledby="label-fact-subtype"
									data-id={r.id}
									id={`fact_subtype_${r.id}`}
									name="subtype"
									options={personFactTypes}
									required
									setValue={setExistingRowPart}
									type="select"
									value={r.subtype}
								/>
							</td>
							<td>
								<Field
									aria-labelledby="label-fact-value"
									data-id={r.id}
									id={`fact_value_${r.id}`}
									name="fact_value"
									required
									setValue={setExistingRowPart}
									value={r.fact_value}
								/>
							</td>
							<td>
								<div className="action-buttons">
									<button
										className="formosa-button button--danger-outline"
										data-id={r.id}
										onClick={removeRow}
										type="button"
									>
										Remove
									</button>
									<button
										className="formosa-button button--outline"
										data-id={r.id}
										onClick={(e) => { setShowCitationsModal(e); }}
										type="button"
									>
										{`Citations (${r.citations.length})`}
									</button>
								</div>
							</td>
						</tr>
					)) : null}
				</tbody>
				<tfoot>
					<tr>
						<td>
							<Field
								aria-labelledby="label-fact-type"
								id="new_subtype"
								name="subtype"
								options={personFactTypes}
								setValue={setNewRowPart}
								type="select"
								value={newRow.subtype}
							/>
						</td>
						<td>
							<Field
								aria-labelledby="label-fact-value"
								id="new_fact_value"
								name="fact_value"
								setValue={setNewRowPart}
								value={newRow.fact_value}
							/>
						</td>
						<td>
							<button className="formosa-button button--success-outline" data-cy="add" onClick={addRow} type="button">Add</button>
						</td>
					</tr>
				</tfoot>
			</table>

			{showCitationsModal && (
				<Citations
					row={row}
					showModal={showCitationsModal}
					setShowModal={setShowCitationsModal}
					setRow={setRow}
					type="facts"
				/>
			)}
		</>
	);
}

PersonFacts.propTypes = {
	row: PropTypes.object.isRequired,
	setRow: PropTypes.func.isRequired,
};
