import Citations from '../Tabs/Citations';
import Fields from './Fields';
import Notes from '../../../Components/Notes';
import PropTypes from 'prop-types';
import React from 'react';
import TabPanel from '../../../Components/TabPanel';
import Tabs from '../../../Components/Tabs';

export default function SourceTabs({ row, setRow }) {
	return (
		<Tabs defaultTab="basics" title={row.id ? row.name : ''} updateUrl>
			<TabPanel id="basics" label="Basics">
				<Fields formType="edit" row={row} />
			</TabPanel>
			<TabPanel id="notes" label={`Notes (${row.notes.length})`}>
				<Notes row={row} setRow={setRow} type="sources" />
			</TabPanel>
			<TabPanel id="citations" label={`Citations (${row.citations ? row.citations.length : 0})`}>
				<Citations row={row} />
			</TabPanel>
		</Tabs>
	);
}

SourceTabs.propTypes = {
	row: PropTypes.object.isRequired,
	setRow: PropTypes.func.isRequired,
};
