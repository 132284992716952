import React, { useState } from 'react';
import Citations from '../../../Components/Citations';
import { Field } from '@jlbelanger/formosa';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

export default function PersonNames({ row, setRow }) {
	const defaultNewRow = {
		prefix: '',
		given: '',
		nickname: '',
		surname: '',
		suffix: '',
		citations: [],
	};
	const [newRow, setNewRow] = useState(defaultNewRow);
	const [showCitationsModal, setShowCitationsModal] = useState(false);

	const setExistingRowPart = (value, e) => {
		const newRows = [...row.names];
		const id = e.target.getAttribute('data-id');
		const index = newRows.findIndex((r) => (r.id === id));
		if (index > -1) {
			const newValue = { ...newRows[index] };
			newValue[e.target.name] = value;
			newRows[index] = newValue;
			setRow({ ...row, names: newRows });
		}
	};

	const setNewRowPart = (value, e) => {
		setNewRow({ ...newRow, [e.target.name]: value });
	};

	const addRow = () => {
		const newRows = row.names ? [...row.names] : [];
		newRows.push({ ...newRow, id: `temp-${uuidv4()}`, type: 'names', person: { id: 'temp-this-id', type: 'people' } });
		setRow({ ...row, names: newRows });
		setNewRow({ ...defaultNewRow });
	};

	const removeRow = (e) => {
		const newRows = row.names ? [...row.names] : [];
		const id = e.target.getAttribute('data-id');
		const index = newRows.findIndex((r) => (r.id === id));
		if (index > -1) {
			newRows.splice(index, 1);
			setRow({ ...row, names: newRows });
		}
	};

	return (
		<>
			<table>
				<caption className="sr">Names</caption>
				<colgroup>
					<col />
					<col />
					<col />
					<col />
					<col />
					<col className="w-0" />
				</colgroup>
				<thead>
					<tr>
						<th id="label-name-prefix">Prefix</th>
						<th id="label-name-given">Given</th>
						<th id="label-name-nickname">Nickname</th>
						<th id="label-name-surname">Surname</th>
						<th id="label-name-suffix">Suffix</th>
						<th id="label-name-actions">Actions</th>
					</tr>
				</thead>
				<tbody>
					{row.names ? row.names.map((r) => (
						<tr key={r.id}>
							<td>
								<Field
									aria-labelledby="label-name-prefix"
									data-id={r.id}
									id={`name_prefix_${r.id}`}
									name="prefix"
									setValue={setExistingRowPart}
									value={r.prefix || ''}
								/>
							</td>
							<td>
								<Field
									aria-labelledby="label-name-given"
									data-id={r.id}
									id={`name_given_${r.id}`}
									name="given"
									setValue={setExistingRowPart}
									value={r.given || ''}
								/>
							</td>
							<td>
								<Field
									aria-labelledby="label-name-nickname"
									data-id={r.id}
									id={`name_nickname_${r.id}`}
									name="nickname"
									setValue={setExistingRowPart}
									value={r.nickname || ''}
								/>
							</td>
							<td>
								<Field
									aria-labelledby="label-name-surname"
									data-id={r.id}
									id={`name_surname_${r.id}`}
									name="surname"
									setValue={setExistingRowPart}
									value={r.surname || ''}
								/>
							</td>
							<td>
								<Field
									aria-labelledby="label-name-suffix"
									data-id={r.id}
									id={`name_suffix_${r.id}`}
									name="suffix"
									setValue={setExistingRowPart}
									value={r.suffix || ''}
								/>
							</td>
							<td className="action-buttons">
								<div className="action-buttons">
									<button
										className="formosa-button button--danger-outline"
										data-id={r.id}
										onClick={removeRow}
										type="button"
									>
										Remove
									</button>
									<button
										className="formosa-button button--outline"
										data-id={r.id}
										onClick={(e) => { setShowCitationsModal(e); }}
										type="button"
									>
										{`Citations (${r.citations.length})`}
									</button>
								</div>
							</td>
						</tr>
					)) : null}
				</tbody>
				<tfoot>
					<tr>
						<td>
							<Field
								aria-labelledby="label-name-prefix"
								id="new_name_prefix"
								name="prefix"
								setValue={setNewRowPart}
								value={newRow.prefix}
							/>
						</td>
						<td>
							<Field
								aria-labelledby="label-name-given"
								id="new_name_given"
								name="given"
								setValue={setNewRowPart}
								value={newRow.given}
							/>
						</td>
						<td>
							<Field
								aria-labelledby="label-name-nickname"
								id="new_name_nickname"
								name="nickname"
								setValue={setNewRowPart}
								value={newRow.nickname}
							/>
						</td>
						<td>
							<Field
								aria-labelledby="label-name-surname"
								id="new_name_surname"
								name="surname"
								setValue={setNewRowPart}
								value={newRow.surname}
							/>
						</td>
						<td>
							<Field
								aria-labelledby="label-name-suffix"
								id="new_name_suffix"
								name="suffix"
								setValue={setNewRowPart}
								value={newRow.suffix}
							/>
						</td>
						<td>
							<button className="formosa-button button--success-outline" data-cy="add" onClick={addRow} type="button">Add</button>
						</td>
					</tr>
				</tfoot>
			</table>

			{showCitationsModal && (
				<Citations
					row={row}
					showModal={showCitationsModal}
					setShowModal={setShowCitationsModal}
					setRow={setRow}
					type="names"
				/>
			)}
		</>
	);
}

PersonNames.propTypes = {
	row: PropTypes.object.isRequired,
	setRow: PropTypes.func.isRequired,
};
