import { Alert, Api, FormosaContext } from '@jlbelanger/formosa';
import { beforeSubmit, errorMessageText } from '../../Utilities/Helpers';
import React, { useContext, useEffect, useState } from 'react';
import { removeRow, updateRow } from '../../../appSlice';
import { useHistory, useParams } from 'react-router-dom';
import Error from '../../Error';
import MetaTitle from '../../Components/MetaTitle';
import Modal from '../../Components/Modal';
import MyForm from '../../Components/MyForm';
import { ReactComponent as PlaceIcon } from '../../../svg/place.svg';
import Submit from '../../Components/Submit';
import Tabs from './Partials/Tabs';
import { useDispatch } from 'react-redux';

export default function PlaceEdit() {
	const api = Api.instance();
	const { addToast, disableWarningPrompt, enableWarningPrompt } = useContext(FormosaContext);
	const dispatch = useDispatch();
	const history = useHistory();
	const { id, tree } = useParams();
	const [row, setRow] = useState(null);
	const [error, setError] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteError, setDeleteError] = useState(false);
	const relationshipNames = [
		'notes',
		'notes.citations',
		'notes.citations.source',
		'parent',
	];
	const relationshipsToAddOnly = [
		'notes.citations.record',
		'notes.record',
	];
	const relationshipsToGetOnly = [
		'children',
		'events',
		'events.record',
		'sources',
	];
	const include = `include=${relationshipNames.concat(relationshipsToGetOnly).join(',')}`;

	useEffect(() => {
		setRow(null);
		api(`places/${id}?filter[tree.slug][eq]=${tree}&${include}`)
			.catch((response) => {
				setError(response);
			})
			.then((response) => {
				if (!response) {
					return;
				}
				setRow(response);
			});
	}, [id, tree]);

	if (error) {
		return (
			<Error error={error} />
		);
	}

	if (row === null) {
		return null;
	}

	const deleteRow = () => {
		setShowDeleteModal(false);
		disableWarningPrompt();
		Api.delete(`places/${id}`)
			.catch((response) => {
				setDeleteError(errorMessageText(response));
			})
			.then((response) => {
				enableWarningPrompt();
				if (!response) {
					return;
				}
				addToast('Place deleted successfully.', 'success');
				history.push(`/trees/${tree}`);
				dispatch(removeRow({ id, type: 'places' }));
			});
	};

	return (
		<>
			<MetaTitle icon={(<PlaceIcon />)} title={row.name}>
				<button
					className="formosa-button formosa-button--danger"
					onClick={(e) => {
						setDeleteError(false);
						setShowDeleteModal(e);
					}}
					type="button"
				>
					Delete
				</button>
				<Submit />
			</MetaTitle>

			{deleteError && (<Alert type="error">{deleteError}</Alert>)}

			<MyForm
				afterSubmitSuccess={(response) => {
					setRow(response);
					dispatch(updateRow({ id, row: response, type: 'places' }));
				}}
				beforeSubmit={beforeSubmit}
				errorMessageText={errorMessageText}
				htmlId="form"
				id={id}
				method="PUT"
				params={include}
				path="places"
				preventEmptyRequest
				relationshipNames={relationshipNames.concat(relationshipsToAddOnly)}
				row={row}
				setRow={setRow}
				successToastText="Place saved successfully."
			>
				<Tabs
					hasChildren={row.children.length > 0}
					hasEvents={row.events.length > 0}
					hasSources={row.sources.length > 0}
					row={row}
					setRow={setRow}
				/>
			</MyForm>

			{showDeleteModal && (
				<Modal
					event={showDeleteModal}
					okButtonClass="formosa-button--danger"
					okButtonText="Delete"
					onClickOk={deleteRow}
					onClickCancel={() => { setShowDeleteModal(false); }}
					text="Are you sure you want to delete this place?"
				/>
			)}
		</>
	);
}
