import { labelFn, valueKey } from '../Utilities/Autocomplete';
import React, { useState } from 'react';
import { Field } from '@jlbelanger/formosa';
import Modal from './Modal';
import PropTypes from 'prop-types';
import { selectSources } from '../../appSlice';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

export default function Citations({ row, showModal, setShowModal, setRow, type }) {
	const defaultNewRow = {
		source: '',
		source_page: '',
		content: '',
	};
	const { tree } = useParams();
	const sources = useSelector(selectSources);
	const [newRow, setNewRow] = useState(defaultNewRow);
	const targetId = showModal.target.getAttribute('data-id');
	const i = row[type].findIndex((r) => (r.id === targetId));
	const record = { ...row[type][i] };
	const [originalCitations] = useState([...record.citations]);

	const setExistingRowPart = (value, e) => {
		const newCitations = [...record.citations];
		const id = e.target.getAttribute('data-id');
		const index = newCitations.findIndex((r) => (r.id === id));
		if (index > -1) {
			const newValue = { ...newCitations[index] };
			newValue[e.target.name] = value;
			newCitations[index] = newValue;

			const newRows = [...row[type]];
			newRows[i] = { ...record, citations: newCitations };

			setRow({ ...row, [type]: newRows });
		}
	};

	const setNewRowPart = (value, e) => {
		setNewRow({ ...newRow, [e.target.name]: value });
	};

	const addRow = () => {
		const newCitations = record.citations ? [...record.citations] : [];
		newCitations.push({ ...newRow, id: `temp-${uuidv4()}`, type: 'citations', record: { id: 'temp-this-id', type } });

		const newRows = [...row[type]];
		newRows[i] = { ...record, citations: newCitations };

		setRow({ ...row, [type]: newRows });
		setNewRow({ ...defaultNewRow });
	};

	const removeRow = (e) => {
		const newCitations = record.citations ? [...record.citations] : [];
		const id = e.target.getAttribute('data-id');
		const index = newCitations.findIndex((r) => (r.id === id));
		if (index > -1) {
			newCitations.splice(index, 1);

			const newRows = [...row[type]];
			newRows[i] = { ...record, citations: newCitations };

			setRow({ ...row, [type]: newRows });
		}
	};

	const submitModal = () => {
		document.querySelector('[form="form"][type="submit"]').click();
		setShowModal(false);
	};

	const onClickCancel = () => {
		setShowModal(false);

		const newRows = [...row[type]];
		newRows[i] = { ...record, citations: originalCitations };
		setRow({ ...row, [type]: newRows });
	};

	return (
		<Modal
			event={showModal}
			okButtonClass="formosa-button--success"
			okButtonText="Save"
			onClickOk={submitModal}
			onClickCancel={onClickCancel}
		>
			<table>
				<caption>Citations</caption>
				<colgroup>
					<col className="w-0" />
					<col className="w-0" />
					<col className="w-100" />
					<col className="w-0" />
				</colgroup>
				<thead>
					<tr>
						<th id="label-citation-source">Source</th>
						<th id="label-citation-page">Page</th>
						<th id="label-citation-content">Content</th>
						<th id="label-citation-actions">Actions</th>
					</tr>
				</thead>
				<tbody>
					{record.citations ? record.citations.map((r) => (
						<tr key={r.id}>
							<td>
								<Field
									afterAdd={() => {
										document.getElementById(`citation_source_page_${r.id}`).focus();
									}}
									aria-labelledby="label-citation-source"
									data-id={r.id}
									id={`citation_source_${r.id}`}
									inputAttributes={{ required: true }}
									labelFn={(option) => (labelFn(tree, option))}
									max={1}
									name="source"
									options={sources}
									setValue={setExistingRowPart}
									type="autocomplete"
									value={r.source}
									valueKey={valueKey}
								/>
							</td>
							<td>
								<Field
									aria-labelledby="label-citation-page"
									data-id={r.id}
									id={`citation_source_page_${r.id}`}
									name="source_page"
									onKeyDown={(e) => {
										if (e.key === 'Enter') {
											submitModal();
										}
									}}
									setValue={setExistingRowPart}
									size={5}
									value={r.source_page || ''}
								/>
							</td>
							<td>
								<Field
									aria-labelledby="label-citation-content"
									data-id={r.id}
									id={`citation_content_${r.id}`}
									name="content"
									setValue={setExistingRowPart}
									type="textarea"
									value={r.content || ''}
								/>
							</td>
							<td>
								<div className="action-buttons">
									<button
										className="formosa-button button--danger-outline"
										data-id={r.id}
										onClick={removeRow}
										type="button"
									>
										Remove
									</button>
								</div>
							</td>
						</tr>
					)) : null}
				</tbody>
				<tfoot>
					<tr>
						<td>
							<Field
								afterAdd={() => {
									document.getElementById('new_citation_source_page').focus();
								}}
								aria-labelledby="label-citation-source"
								id="new_citation_source"
								labelFn={(option) => (labelFn(tree, option))}
								max={1}
								name="source"
								options={sources}
								setValue={setNewRowPart}
								type="autocomplete"
								value={newRow.source}
								valueKey={valueKey}
							/>
						</td>
						<td>
							<Field
								aria-labelledby="label-citation-page"
								id="new_citation_source_page"
								name="source_page"
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										if (newRow.source) {
											addRow();
										} else {
											submitModal();
										}
									}
								}}
								setValue={setNewRowPart}
								size={5}
								value={newRow.source_page}
							/>
						</td>
						<td>
							<Field
								aria-labelledby="label-citation-content"
								id="new_citation_content"
								name="content"
								setValue={setNewRowPart}
								type="textarea"
								value={newRow.content}
							/>
						</td>
						<td>
							<button className="formosa-button button--success-outline" data-cy="add" onClick={addRow} type="button">Add</button>
						</td>
					</tr>
				</tfoot>
			</table>
		</Modal>
	);
}

Citations.propTypes = {
	row: PropTypes.object.isRequired,
	showModal: PropTypes.object.isRequired,
	setShowModal: PropTypes.func.isRequired,
	setRow: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
};
