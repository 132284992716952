import React, { useEffect, useState } from 'react';
import { Api } from '@jlbelanger/formosa';
import Error from '../../Error';
import { init } from '../../../appSlice';
import MetaTitle from '../../Components/MetaTitle';
import RoutesTree from '../../RoutesTree';
import Sidebar from './Partials/Sidebar';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

export default function TreeView() {
	const api = Api.instance();
	const dispatch = useDispatch();
	const { tree } = useParams();
	const [row, setRow] = useState(null);
	const [error, setError] = useState(false);

	useEffect(() => {
		let url = `trees?filter[slug][eq]=${tree}`;
		url += '&include=people,places,sources';
		url += '&fields[people]=meta.name,meta.slug';
		url += '&fields[places]=meta.full_name,meta.slug';
		url += '&fields[sources]=name,meta.slug';
		api(url)
			.catch((response) => {
				setError(response);
			})
			.then((response) => {
				if (!response) {
					return;
				}
				if (response.length <= 0) {
					setError({});
					return;
				}
				setRow(response[0]);
				dispatch(init({ tree: response[0] }));
			});
	}, [tree]);

	if (error) {
		return (
			<Error error={error} />
		);
	}

	if (row === null) {
		return (
			<article className="tree" id="article">
				<MetaTitle />
				<section id="article-content" />
			</article>
		);
	}

	return (
		<article className="tree" id="article">
			<MetaTitle hideTitleText title={row.name} />
			<Sidebar row={row} />
			<section id="article-content">
				<RoutesTree />
			</section>
		</article>
	);
}
