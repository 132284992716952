import { capitalize, toSlug } from '../../../Utilities/Helpers';
import React, { useState } from 'react';
import { Field } from '@jlbelanger/formosa';
import Modal from '../../../Components/Modal';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function TreeSidebarList({ addComponent, rows, singular, tree, type }) {
	const [search, setSearch] = useState('');
	const [showAddModal, setShowAddModal] = useState(false);
	const hasRows = rows.length > 0;

	let filteredRows = rows;
	const normalizedSearch = search ? toSlug(search) : '';
	if (search) {
		filteredRows = filteredRows.filter((r) => (r.slug.includes(normalizedSearch)));
	}

	const highlightSearch = (s) => {
		if (!normalizedSearch) {
			return s;
		}
		const regex = new RegExp(`(${normalizedSearch})`, 'gi');
		return s.split(regex).map((part, i) => (
			// eslint-disable-next-line react/no-array-index-key
			part.match(regex) ? <mark key={i}>{part}</mark> : part
		));
	};

	const AddComponent = addComponent;

	return (
		<>
			<div className="sidebar__links">
				<button
					aria-label={`Add new ${type}`}
					className="formosa-button formosa-button--success"
					onClick={(e) => { setShowAddModal({ e, row: {} }); }}
					type="button"
				>
					Add New
				</button>
			</div>

			{showAddModal && (
				<Modal
					event={showAddModal.e}
					okButtonClass="formosa-button--success"
					okButtonText="Add"
					okButtonAttributes={{
						form: 'add-form',
						type: 'submit',
					}}
					onClickCancel={() => {
						setShowAddModal(false);
					}}
				>
					<h2>{`Add ${capitalize(singular)}`}</h2>

					<AddComponent setShowAddModal={setShowAddModal} />
				</Modal>
			)}

			{hasRows && (
				<Field
					aria-label={`Search ${type}`}
					id={`search-${type}`}
					setValue={setSearch}
					type="search"
					value={search}
					wrapperClassName="sidebar__search"
				/>
			)}

			{filteredRows.length > 0 ? (
				<ul className="list list--scrollable">
					{filteredRows.map((r) => (
						<li className="list__item" key={r.id}>
							<NavLink className="list__link" to={`/trees/${tree}/${type}/${r.id}`}>{highlightSearch(r.name)}</NavLink>
						</li>
					))}
				</ul>
			) : (
				<p className="list__no-results">{`No matching ${type} found.`}</p>
			)}
		</>
	);
}

TreeSidebarList.propTypes = {
	addComponent: PropTypes.func.isRequired,
	singular: PropTypes.string.isRequired,
	tree: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	rows: PropTypes.array.isRequired,
};
