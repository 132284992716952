import { addRow, selectTreeId } from '../../../appSlice';
import { beforeSubmit, errorMessageText } from '../../Utilities/Helpers';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Fields from './Partials/Fields';
import MyForm from '../../Components/MyForm';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

export default function PersonAdd({ setShowAddModal }) {
	const dispatch = useDispatch();
	const history = useHistory();
	const { tree } = useParams();
	const id = useSelector(selectTreeId);
	const defaultRow = useMemo(() => ({
		events: [
			{
				id: `temp-${uuidv4()}`,
				type: 'events',
				subtype: 'BIRT',
				record: {
					id: 'temp-this-id',
					type: 'people',
				},
			},
		],
		names: [
			{
				id: `temp-${uuidv4()}`,
				type: 'names',
				record: {
					id: 'temp-this-id',
					type: 'people',
				},
			},
		],
		tree: { id, type: 'trees' },
	}), []);
	const [row, setRow] = useState(JSON.parse(JSON.stringify(defaultRow)));
	const relationshipNames = [
		'events',
		'events.place',
		'events.record',

		'names',
		'names.record',

		'tree',
	];

	return (
		<MyForm
			afterSubmitSuccess={(response) => {
				history.push(`/trees/${tree}/people/${response.id}`);
				dispatch(addRow({ row: response, type: 'people' }));
				setShowAddModal(false);
			}}
			beforeSubmit={beforeSubmit}
			defaultRow={defaultRow}
			errorMessageText={errorMessageText}
			htmlId="add-form"
			method="POST"
			path="people"
			preventEmptyRequest
			relationshipNames={relationshipNames}
			row={row}
			setRow={setRow}
			successToastText="Person added successfully."
		>
			<Fields />
		</MyForm>
	);
}

PersonAdd.propTypes = {
	setShowAddModal: PropTypes.func.isRequired,
};
