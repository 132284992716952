import { Api, FormosaContext } from '@jlbelanger/formosa';
import React, { useContext, useState } from 'react';
import Auth from './Utilities/Auth';
import { errorMessageText } from './Utilities/Helpers';
import { NavLink } from 'react-router-dom';

export default function Header() {
	const { addToast } = useContext(FormosaContext);
	const [showDropdown, setShowDropdown] = useState(false);

	const closeDropdown = (e) => {
		if (e.target.getAttribute('data-cy') !== 'username') {
			setShowDropdown(false);
			document.removeEventListener('click', closeDropdown);
		}
	};

	const toggleDropdown = () => {
		if (!showDropdown) {
			setShowDropdown(true);
			document.addEventListener('click', closeDropdown);
		} else {
			setShowDropdown(false);
			document.removeEventListener('click', closeDropdown);
		}
	};

	const logout = () => {
		Api.delete('auth/logout')
			.catch((response) => {
				if (response.status === 401) {
					return;
				}
				addToast(errorMessageText(response), 'error');
			})
			.then(() => {
				Auth.logout();
			});
	};

	return (
		<header id="header">
			<div id="header__contain">
				<div id="title">
					<NavLink id="title__link" to="/">{process.env.REACT_APP_TITLE}</NavLink>
				</div>
				{Auth.isLoggedIn() && (
					<>
						<button className="formosa-button nav__button" data-cy="username" onClick={toggleDropdown} type="button">
							{Auth.username()}
						</button>
						{showDropdown && (
							<div className="dropdown">
								<NavLink className="formosa-button button--secondary dropdown__button" data-cy="profile" to="/profile">
									Profile
								</NavLink>
								<button className="formosa-button button--secondary dropdown__button" data-cy="logout" onClick={logout} type="button">
									Logout
								</button>
							</div>
						)}
					</>
				)}
			</div>
		</header>
	);
}
